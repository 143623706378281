@import '../../styles/colors';

.wrapper {
  max-width: 162rem;
  margin: 0 auto;
}

.titleHeader {
  font-size: 3rem;
  font-weight: bold;
  margin-block: 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
    font-size: 3rem;
  }
}

.doctorContainer {
  display: grid;
  grid-gap: 2.5rem;
  padding-top: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(312px, 1fr));
}
