@import '../../styles/colors';

.modalContentContainer {
  box-shadow: 0 0.3rem 1rem $DropshadowColor;
  border-radius: 2rem;
  background-color: $LightGrayNoOpacity;
  overflow: auto;
  outline: none;
  padding: 2rem;
  min-height: 75rem;
  max-height: 75rem;
  min-width: 65rem;
  max-width: 65rem;
}

.modalOverlay {
  position: fixed;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $ModalBackgroundColor;
}

.closeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;

  h1 {
    font-size: 2.2rem;
    margin-block: 0;
  }

  svg {
    cursor: pointer;
    font-size: 3rem;
  }
}

.doctorProfileContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  .doctorImage {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: cover;
  }

  h2 {
    font-size: 2.4rem;
    margin-block: 0;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  h2 {
    margin-block: 0;
    font-size: 2rem;
  }

  .inputField {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    box-shadow: 0 0.3rem 0.6rem $DropshadowColor;
    border-radius: 0.8rem;
    width: 30rem;

    .inputFieldLabel {
      font-size: 1.6rem;
      font-weight: normal;
    }

    .inputFieldInput {
      width: 100%;
      height: 3rem;
      border-radius: 0.8rem;
      border: 1px solid $LightGray;
      padding: 1rem;
      font-size: 1.6rem;
      font-weight: normal;
      outline: none;
      transition: all 0.2s ease-in-out;

      &:focus {
        border: 1px solid $Black;
      }
    }
  }
}

.patientDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.weekDaysContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;

  .weekDay {
    display: flex;
    flex-direction: column;
    height: 4rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;
    background-color: $White;
    box-shadow: 0 0.3rem 0.6rem $DropshadowColor;
    cursor: pointer;
    padding: 2rem;
    text-align: center;

    span {
      font-size: 1.6rem;
      font-weight: normal;
      user-select: none;
    }
  }
}

.step1Wrapper {
  display: flex;
  flex-direction: column;
}

.timeSlotsContainer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  padding-top: 2rem;
  justify-content: center;

  .timeSlot {
    border-radius: 5rem;
    min-width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 1rem 2rem;
    background-color: $White;
    border: 0.1rem solid $Black;

    span {
      font-size: 1.4rem;
    }
  }
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  h2 {
    font-size: 2.4rem;
    margin-block: 0;
    margin-bottom: 5rem;
  }

  img {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
  }
}

.ctaButton {
  width: 100%;
}

// Modifier classes
.mod {
  &SelectedDate {
    background-color: rgb(227, 227, 227) !important;
  }

  &BlockedDate {
    background-color: $LightGray !important;
    cursor: not-allowed;
  }

  &PaddingTop {
    margin-top: 5rem;
  }
}
