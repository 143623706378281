@import './colors';
@import './media-queries';
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

html,
body {
  color: $Black;
  font-family: 'Rubik', sans-serif;
  margin: 0;
  font-size: 62.5%;
}
